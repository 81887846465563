import Shipping from "./shipping";
import { handleLoadVideos } from "./utilities";

var $window = $(window);
var $body = $('body');
var $header = $('#header');
var $wrapper = $('#wrapper');
var spaceToHeaderFixed;
var language = $('html').attr('lang');
var timeTransitionDefault = 350;
var texts = [];

window.page = $body.data('page');

texts['pt-BR'] = {
  quantity: 'quantidade',
  price: 'preço',
  cart_empty: 'O seu carrinho está vazio',
  go_product: 'Ir para a página do produto',
  btn_edit_cart: 'Editar pedido',
  btn_checkout: 'Finalizar pedido',
  btn_success: 'Adicionado!',
  errorsTxt: {
    withoutSku: 'Selecione um atributo para o produto'
  }
};

function getText (key, subKey) {
  if (typeof subKey == 'undefined') {
    return texts[language][key];
  } else {
    return texts[language][key][subKey];
  }
}

window.store = {
  config: {
    // Seletor do botão de comprar
    selectorBtnBuy: '[data-action="add-cart"]',
    selectorBtnBuyAssinatura: '[data-action="add-cart-assinatura"]',
    // Seletor do elemento que engloba um produto
    selectorWrapperProduct: '[data-box-produto]',
    selectorWrapperProductAssinatura: '[data-box-produto-assinatura]',
    selectorFormProduct: '[data-form-product]',
    selectorFormProductAssinatura: '[data-form-product-assinatura]',
    // Se a store tem versão internacional ou não
    hasInternacionalVersion: false,
    errorsTxt: {
      withoutSku: getText('errorsTxt', 'withoutSku'),
    },
    // configuração de comportamento ao add um produto
    addToCartOpenCartpopup: true,
    addToCartOpenCartpopupMobile: false,
    cartCount: {
      selector: '[data-cart-count]',
      attr: '[data-cart-count-attr]',
      text: '[data-cart-item-text]'
    },
    priceProds: {
      selector: '[data-update-price]',
      attr: 'update-price',
    }
  },
  formatMoney: function (value) {
    // FORMATA UM VALOR
    return "R$ " + value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
  },
  validateQuantity: function (_val) {
    // VALIDA SE A QUANTIDADE INFORMADA É UM NÚMERO
    if (!isNaN(_val)) {
      if (parseInt(_val) > 0) {
        return true;
      }
    }

    return false;
  },
  getClearNumber: function (_val) {
    // RETORNA UM NÚMERO LIMPO COMO INT
    if (!isNaN(_val)) {
      clearNumber = parseInt(_val);

      return clearNumber;
    }

    return false;
  },
  validateFormProduct: function (_$form) {
    // VALIDA O FORM DE PRODUTO PARA VER SE O PRODUTO PODE SER ADICIONADO
    var $parent = _$form.closest('[data-box-produto]');
    var $btnComprar = _$form.find('[data-action="add-cart"]');
    var validated = true;
    var errorsTxt = [];

    if ($btnComprar.data('available') == 'false') {
      validated = false;
      errorsTxt.push('');
    } else {
      //  verifica se o sku foi selecionado
      if (_$form.find('[name="sku"]').val() == '') {
        validated = false;
        errorsTxt.push('Selecione um atributo para o produto');
      }

      //  verifica se a quantidade é válida
      //console.log(_$form.find('input[name="quantity"]').val());
      if (_$form.find('input[name="quantity"]').val() <= 0) {
        //console.log("qtd invalida");
        validated = false;
        errorsTxt.push('Quantidade indisponível');
      }
    }

    return {validated: validated, errors: errorsTxt};
  },
  setRespValidateProduct: function (_resp, _$form, _$boxResponse) {
    // var $boxResult = _$form.find('.resp-validate');
    var $boxResult = _$boxResponse;
    var htmlErrors = '';

    if (_resp.validated) {
      $boxResult.empty();

    } else {
      for (var i = _resp.errors.length - 1; i >= 0; i--) {
        htmlErrors += '<span class="msg error">' + _resp.errors[i] + '</span>';
      }

      $boxResult.html(htmlErrors);
    }
  },
  addItemResultDefault: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuy);

      $btnComprar.addClass('success').html('Produto adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlCart;
      }, 150);

    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof(_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  addItemResultDefaultAssinatura: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuyAssinatura);

      $btnComprar.addClass('success').html('Adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlAssinaturaCart;
      }, 150);

    } else if (_typeResult == 'erro-adicionar') {
      if (typeof _result == 'object') {
        var errorText = _result;
      } else {
        var errorText = JSON.parse(_result);
      }

      if (typeof (_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);
      }
    }
  },
  addItem: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find('[data-action="add-cart"]');
    var urlAdd = '/carrinho/adicionar';
    var urlUpdate = '/carrinho';
    var url = urlAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find('[data-form-product] .msg-response:not(.resp-validate)');

    //console.info('addItem');
    //console.info('addItem', _$form.serializeArray());

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: form,
        beforeSend: function () {
          $btnComprar.addClass('adding');
        }
      })
      .done(function(resp) {
        //console.log("success");
        //console.info(resp);

        if (typeof template_store.addItemResult == 'function') {
          template_store.addItemResult(_$form, 'produto-adicionado', resp.items_count, $boxResponse);
        } else {
          template_store.addItemResultDefault(_$form, 'produto-adicionado', resp, $boxResponse);
        }
      })
      .fail(function(resp) {
        //console.log("error");
        //console.error(resp);
        //console.error(resp.responseText);
        //console.error(resp.responseText.error);

        if (typeof template_store.addItemResult == 'function') {
          template_store.addItemResult(_$form, 'erro-adicionar', resp.items_count, $boxResponse);
        } else {
          template_store.addItemResultDefault(_$form, 'erro-adicionar', resp, $boxResponse);
        }
      })
      .always(function() {
        //console.log("complete");
        $btnComprar.removeClass('adding');
      });
    }
  },
  addItemAssinatura: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find(store.config.selectorBtnBuyAssinatura);
    var url = urlAssinaturaCartAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find(store.config.selectorFormProductAssinatura + ' .msg-response:not(.resp-validate)');
    var data = {};

    data.subscription_item = {
      quantity: _$form.find('[name="quantity"]').val(),
      sku: _$form.find('[name="sku"]').val(),
      extra: {
        vindi_plan_id: _$form.find('[name="extra[vindi_plan_id]"]').val()
      }
    }

    //console.info('addItemAssinatura');
    //console.info('addItemAssinatura', data);
    //console.info('addItemAssinatura', JSON.stringify(data));

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        // dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        beforeSend: function () {
          $btnComprar.addClass('adding');
        }
      })
        .done(function (resp) {
          //console.log("success");
          //console.info(resp);

          if (typeof template_store.addItemResult == 'function') {
            template_store.addItemResult(_$form, 'produto-adicionado', resp, $boxResponse);
          } else {
            template_store.addItemResultDefaultAssinatura(_$form, 'produto-adicionado', resp, $boxResponse);
          }
        })
        .fail(function (xhr, status, error) {
          //console.log("error");
          //console.error(xhr);
          //console.error(status);
          //console.error(error);

          var resp = {
            error: 'Houve um problema ao adicionar. Tente mais tarde ou entre em contato.',
          }

          if (typeof template_store.addItemResult == 'function') {
            template_store.addItemResult(_$form, 'erro-adicionar', resp, $boxResponse);
          } else {
            template_store.addItemResultDefaultAssinatura(_$form, 'erro-adicionar', resp, $boxResponse);
          }
        })
        .always(function () {
          //console.log("complete");
          $btnComprar.removeClass('adding');
        });
    }
  },
  deleteItem: function (_itemId, _$item, removeItemResult) {
    var _this = this;

    if (!_$item.hasClass('removing')) {
      $.ajax({
        url: '/carrinho',
        type: 'POST',
        dataType: 'json',
        data: {
          '_method': 'delete',
          'item_id': _itemId
        },
        beforeSend: function () {
          _$item.addClass('removing');
        }
      })
      .done(function(_cart) {
        //console.log("success");

        if ($body.width() > 768) {
          if (store.config.addToCartOpenCartpopup) {
            template_store.headerCart.loadCart(_cart);
            template_store.headerCart.hide();
          }
        } else {
          if (store.config.addToCartOpenCartpopupMobile) {
            template_store.headerCart.loadCart(_cart);
            template_store.headerCart.hide();
          }
        }

        if (typeof removeItemResult == 'function') {
          removeItemResult(_$item, _cart);
        }
      })
      .fail(function(error) {
        //console.log("erro ao remover", error.responseText);
      })
      .always(function() {
        _$item.removeClass('removing');
      });
    }
  },
  setMsgResponse: function (_msg, _type, _$parent) {
    /*
      O tipo pode conter um dos 3 valores: success, warning, error ou CLEAR.
      O type CLEAR limpa as mensagens e faz sumir a mensagem
    */

    // Verifica se não há mais de uma mensagem
    // caso tenha, gera um html para cada mensagem
    if (Array.isArray(_msg)) {
      var msgArr = _msg;
      _msg = '';
      for (var i = msgArr.length - 1; i >= 0; i--) {
        _msg += '<span class="msg error">' + msgArr[i] + '</span>';
      }
    }

    _$parent
      .find('[data-msg-retorno]')
        .removeClass('success')
        .removeClass('warning')
        .removeClass('error');

    _$parent.find('[data-msg-retorno] [data-msg]').empty();

    if (_type != 'clear') {
      _$parent.find('[data-msg-retorno]').addClass(_type);
      _$parent.find('[data-msg-retorno] [data-msg]').html(_msg);
    }
  },
  updateCartCount: function (_itemsCount) {
    var _this = this;

    // $(_this.cartCount.selector).empty().text(_itemsCount);

    $(_this.config.cartCount.attr).data('cart-count-attr', _itemsCount);
    $(_this.config.cartCount.attr).attr('data-cart-count-attr', _itemsCount);

    $(_this.config.cartCount.attr).html(_itemsCount);
    $(_this.config.cartCount.text).html(_itemsCount);
    $(_this.config.cartCount.textLabel).html(_itemsCount > 1 ? 'itens' : 'item');

    if (_itemsCount > 0) {
      $('[data-cart-body]').parent().addClass('active');
    } else {
      $('[data-cart-body]').parent().removeClass('active');
    }

  },
  getCartCount: function () {
    // console.log('getCartCount')
    async function getCartItens () {
      let itens;

      try {
        itens = await $.ajax({
          url: '/carrinho/itens', 
          type: 'GET', 
          dataType: 'text'
        });

        return itens;
      } catch (error) {
        // console.error(error);
      }
    }

    if ($(store.config.cartCount.selector).length > 0) {
      getCartItens().then((total) => {
        var itens = Number.isInteger(parseInt(total));
        if (itens) {
          $(store.config.cartCount.attr).attr('data-cart-count-attr', total);
          $(store.config.cartCount.selector).html(total);
  
          var $boxCart = $('[data-cart-body]');
  
          if ($boxCart.length > 0) {
            if (total > 0) {
              template_store.headerCart.loadCart(total);
            } else {
              $boxCart.html('<div class="empty"><p>Seu carrinho está vazio...</p></div>');
            }
          }
        }
      });
    };
  },
  submitForm: function (_$form) {

    _$form.find('[name="reply_to"]').val(_$form.find('[name="email"]').val());

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: '/webform',
        type: 'POST',
        data: _$form.serialize(),
        beforeSend: function () {
          _$form.addClass('sending');
        }
      })
      .done(function() {
        // console.info('foi');
        // store.setMsgResponse('Mensagem enviada com sucesso!', 'success', _$form);
        _$form.find('[data-msg-retorno] [data-msg-success]').addClass('visible');
        _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');
        _$form.find("button[type=submit]").text('Enviado');

        setTimeout(function() {
          _$form.find("button[type=submit]").text('Enviar');
        }, 3500);
        _$form[0].reset();

        if (_$form[0].dataset.webform == 'newsletter'){
          $('.box-form').addClass('inactive');
          $('.promo-code').addClass('active')
          setTimeout(() => {
            $('.box-form').addClass('inactive');
            $('.promo-code').removeClass('active')
            $('.popup-newsletter').addClass('hide')
          }, 6000)
        }
      })
      .fail(function() {
        // console.info('foi, mas deu ruim');
        _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
        _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
        _$form.find("button[type=submit]").text('Falha ao enviar');
        setTimeout(function() {
          _$form.find("button[type=submit]").text('Enviar');
        }, 3500);
        // store.setMsgResponse('Houve um problema ao enviar sua mensagem, tente novamente! Ou entre em contato conosco pelo telefone (21) 99644-6925 ', 'error', $form);
      })
      .always(function() {
        //console.log("complete");
        _$form.removeClass('sending');
      });
    }
  },
  getPriceProd: function () {
    var selector = store.config.priceProds.selector;
    var attr = store.config.priceProds.attr;

    if ($(selector).length > 0) {
      $(selector).each(function(index, value) {
        var $this = $(this);
        var prodId = $(this).data(attr);
        var url = '/produto/preco/' + prodId;

        if(prodId != '' && prodId != null) {
          $.ajax({
            url: url,
            type: 'GET',
          })
          .done(function(resp) {
            // console.info(resp);
            $this.html(resp);
          })
          .fail(function(resp) {
            // console.error(resp);
          });
        }
      });
    }
  },
  login: {
    validateLogin: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe a senha!');
      }

      return {validated: validated, errors: errorsTxt};
    },
    validateRegister: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe uma senha!');
      }

      if (_$form.find('[name="password"]').val() != '') {
        var pass = _$form.find('[name="password"]').val();
        var confirmationPass = _$form.find('[name="password_confirmation"]').val();

        if (pass != confirmationPass) {
          validated = false;
          errorsTxt.push('Senha e confirmação são diferentes!');
        }
      }

      return {validated: validated, errors: errorsTxt};
    },
    validateRecover: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      return {validated: validated, errors: errorsTxt};
    },
    logar: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/entrar',
          type: 'POST',
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          }
        })
        .done(function(resp) {
          //console.log("success", resp);

          if (resp.error) {
            store.setMsgResponse(resp.error, 'error', _$form);
          }

          if (resp.client) {
            window.location.href = resp.redirect_to;
          }
        })
        .fail(function(resp) {
          //console.error("error", error.responseText);
        })
        .always(function() {
          //console.log("complete");
          _$form.removeClass('sending');
        });
      }
    },
    recoverPassord: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/recuperar_senha',
          type: 'POST',
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          }
        })
        .done(function(resp) {
          //console.log("success", resp);

          if (resp.error) {
            store.setMsgResponse(resp.error, 'error', _$form);
          }

          if (resp.ok) {
            store.setMsgResponse(resp.ok, 'success', _$form);
          }

          /*if (resp.client) {
            window.location.href = resp.redirect_to;
          }*/
        })
        .fail(function(resp) {
          //console.error("error", error.responseText);
        })
        .always(function() {
          //console.log("complete");
          _$form.removeClass('sending');
        });
      }
    },
  },
}

window.template_store = {
  setMenuMobile: function () {
    $("#menu-mobile").mmenu({
      slidingSubmenus: false,
      extensions: [
        "pagedim-black",
        "position-front"
      ],
      navbar: {
        add: true
      }
    },
    {
      offCanvas: {
        pageSelector: "#wrapper"
      }
    });
    var mmenu = $("#menu-mobile").data('mmenu');
    $('[data-action="open-menu-mobile"]').on('click', function(event) {
      event.preventDefault();
      if ($("#menu-mobile").hasClass('mm-opened')) {
        mmenu.close();
      } else {
        mmenu.open();
      }
    });
  },
  // Manda um ajax para o form de avise-me e também para um form da plataforma para o cliente receber um email
  submitFormNotify: function (_$form) {
    var formData = _$form.serialize();

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: _$form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: formData,
        beforeSend: function () {
          _$form.addClass('sending');
        }
      })
        .done(function (resp) {
          //console.log("success");
          //console.info(resp);

          var msgSuccess = 'Você receberá um e-mail quando o produto estiver disponível';

          // Envia um email para o cliente saber que o usuário solicitou o avise-me
          $.ajax({
            url: '/webform',
            type: 'POST',
            data: {
              key: _$form.find('[name="key"]').val(),
              reply_to: _$form.find('[name="email"]').val(),
              email: _$form.find('[name="email"]').val(),
              product_name: _$form.find('[name="nome"]').val(),
              referencia: _$form.find('[name="referencia"]').val(),
              sku: _$form.find('[name="sku"]').val(),
            },
          })
          .done(function (resp) {
            _$form.find("button[type=submit]").text("Enviado");
            setTimeout(function () {
              _$form.find("button[type=submit]").text("Enviar");
            }, 3500);
            _$form[0].reset();
          })
          .fail(function (error) {
            _$form.find("button[type=submit]").text("Falha ao enviar");
            setTimeout(function () {
              _$form.find("button[type=submit]").text("Enviar");
            }, 3500);
          })
          .always(function () {
          })

          if (resp.error) {
            _$form.find('.msg-response').removeClass('happy').addClass('error');
            _$form.find('.msg-response span').html(resp.error);
            _$form.find('.msg-response').slideDown(350);

            setTimeout(function () {
              _$form.find('.msg-response').removeClass('happy').removeClass('error');
              _$form.find('.msg-response span').empty();
            }, 3500);

          } else {
            _$form.find('.msg-response').addClass('happy').removeClass('error');
            _$form.find('.msg-response span').html(msgSuccess);

            setTimeout(function () {
              _$form.find('.msg-response').removeClass('happy').removeClass('error');
              _$form.find('.msg-response span').empty();
            }, 3500);

          }
        })
        .fail(function (resp) {
          //console.log("error");
          //console.error(resp);
          //console.error(resp.responseText);
          //console.error(resp.responseText.error);

          var errorText = JSON.parse(resp.responseText);
          //console.info(errorText);

          _$form.find('.msg-response').removeClass('happy');
          _$form.find('.msg-response span').html(errorText.error);
          _$form.find('.msg-response').slideDown(350);

          setTimeout(function () {
            _$form.find('.msg-response').slideUp(350);
          }, 3500);
        })
        .always(function () {
          _$form.removeClass('sending');
        });
    }
  },
  // Função que executa o comportamento de quando o produto é adicionado no carrinho
  addItemResult: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this;

    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = $(store.config.selectorBtnBuy);

      $btnComprar.addClass('success').html(getText(language));

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      if ($body.width() > 768) {
        if (store.config.addToCartOpenCartpopup) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      } else {
        if (store.config.addToCartOpenCartpopupMobile) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      }

    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof (_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        $('html,body').animate({
          scrollTop: _$boxResponse.offset().top
        }, '250');

        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  headerCart: {
    initDrawer: function () {
      var button = document.getElementById("bt-drawer"); //botão para abrir o cartDrawer no header
      button.addEventListener("click", window.componentCartDrawer.toggle);
    },
    
    loadCart: function (total) {
      store.updateCartCount(total);
      window.componentCartDrawer.open();
    },
    
    show: function () {
      window.componentCartDrawer.open();
    },
  },
  home: {
    setFullbanner: function () {      
      $('[data-fullbanner]').each(function (index, el) {  
        var swiper = new Swiper(el.querySelector('.swiper'), {
          slidesPerView: 1,
          spaceBetween: 0,
          autoplay: {
            delay: 3000,
          },
          speed: 1000,
          watchOverflow: true,
          navigation: {
            nextEl: el.querySelector('.swiper-button-next'),
            prevEl: el.querySelector('.swiper-button-prev'),
            disabledClass: 'swiper-button-disabled'
          },
          pagination: {
            el: el.querySelector('.swiper-pagination'),
            type: 'bullets',
            clickable: true
          },        
        });
      });     
    },
    setSwiperProducts: function(){
      var swiperProducts = new Swiper('.swiper-produtos', {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next.products',
          prevEl: '.swiper-button-prev.products',
        },
        breakpoints: {
          '@0.75': {
            slidesPerView: 1,
          },
          '@1.00': {
            slidesPerView: 2,
          },
          '@1.20': {
            slidesPerView: 4,
          },
          '@1.50': {
            slidesPerView: 4,
          }
        },
      })
    },
    init: function () {      
      this.setFullbanner();
      this.swipersHome();
      this.setSwiperProducts();
      handleLoadVideos();
      window.addEventListener('load', () => {
        document.querySelector('.promo-bar').classList.remove('-hidden')
      })

    },
    swipersHome: function(){

      try {

        //Quando a página carrega seleciona o primeiro swiper da lista
        loadProductSwiper(1)

        //Adiciona evento Click nos links 
        $('.swiper-master .links a').on('click', function(e){
          e.preventDefault();
          $('.swiper-master .links a').removeClass('title');
          $(this).addClass('title');
          var tag = $(this).attr('href');
          $('.swiper-home-produtos').removeClass('active');
          $('[data-link="'+ tag + '"]').addClass('active');
          var val = $(this).attr('value');

          //Instancia o swiper de acordo com o index selecionado
          loadProductSwiper(val);

        });

        //Instancia dinamicamente o swiper selecionado
        function loadProductSwiper(index){
          var swiperHomeProdutos = new Swiper (`.swiper-home-produtos-${index}`, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 10,
            watchOverflow: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
              '@0.75': {
                slidesPerView: 1,
              },
              '@1.00': {
                slidesPerView: 2,
              },
              '@1.20': {
                slidesPerView: 4,
              },
              '@1.50': {
                slidesPerView: 4,
              }
            },
          })
        }

      } catch (error) {
        /*LOG DEV  */ //console.log(`${error}`);
        /*LOG PROD */ //console.log(`ação inesperada em store home:swipersHome`);
      }
    }
  },
  product: {
    $sectionProduct: $('[data-section-product]'),
    $wrapperImages: $('[data-section-product]').find('[data-product-images]'),
    $sliderProduct: $('[data-section-product]').find('[data-product-images] [data-main-slider]'),
    $thumbsProduct: $('[data-section-product]').find('[data-product-images] [data-slider-thumbs]'),
    sliderProduct: [],
    /* 
      Exibe as primeiras imagens do produto
      ** Importante para quando houver imagem por sku **
    */
    showFirstImages: function () {
      var _this = this;
      var variants = vnda.variants.all();
      var $imagesWithoutSkus = _this.$sliderProduct.find('.image-without-sku');
      var $thumbsWithoutSkus = _this.$thumbsProduct.find('.image-without-sku');

      if ($imagesWithoutSkus.length > 0) {
        
        /* 
          Todas as imagens sem skus são exibidas sempre
        */

        $imagesWithoutSkus
          .removeClass('inactive')
          .addClass('active');

        $thumbsWithoutSkus
          .removeClass('inactive')
          .addClass('active');
      } else {
        /* 
          Percorre todas as variantes
        */
        for (var i = 0; i < variants.length; i++) {
          if (variants[i].main) {
            //console.info(variants[i]);

            // Procura a imagem que contém o sku e a exibe
            _this.$sliderProduct.find('[data-image][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');

            _this.$thumbsProduct.find('[data-image-thumb][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');
          }
        }
      }
    },
    setGallery: function () {

      const sliderProduct = document.querySelector('[data-main-slider]');
      const thumbsProduct = document.querySelector('[data-slider-thumbs]');
      const wrapperSlider = document.querySelector('.wrapper-slider')

      var _this = this;
      var gallery = new Swiper(sliderProduct, {
        slidesPerView: 1,
        spaceBetween: 30,
        watchOverflow: true,
        simulateTouch: false,

        navigation: {
          nextEl: wrapperSlider.querySelector('.button-next'),
          prevEl: wrapperSlider.querySelector('.button-prev'),
        },
      })

      var galleryThumbs = new Swiper(thumbsProduct, {
        slidesPerView: 3.5,
        spaceBetween: 15,
        watchOverflow: true,
        direction: 'vertical',
      })

      this.sliderProduct.push(gallery);
      this.sliderProduct.push(galleryThumbs);

      $('[data-slide-index]').on('click', function () {
        // console.log('oie', $(this).data('slide-index') - 1)
        gallery.slideTo($(this).data('slide-index') - 1);
      });     
    },
    submitNotify: function () {
      $(document).on('submit', '[data-form-notify]', function (event) {
        event.preventDefault();
        template_store.submitFormNotify($(this));
      });
    },
    infosCompra: function () {
      $('.infos-compra .minus').on('click', function(e){
        e.preventDefault();
        if ($('.infos-compra [name="quantity"]').val() > 1){          
          $('.infos-compra [name="quantity"]').val(parseInt($('.infos-compra [name="quantity"]').val(), 10) - 1)
        }
      });

      $('.infos-compra .plus').on('click', function(e){
        e.preventDefault();
        $('.infos-compra [name="quantity"]').val(parseInt($('.infos-compra [name="quantity"]').val(), 10) + 1)
      });
    },
    setRelated: function () {
       var galleryRelated = new Swiper('.produtos-relacionados .swiper', {
        slidesPerView: 1,
        spaceBetween: 20,
        watchOverflow: true,
        navigation: {
          nextEl: $('.produtos-relacionados').find('.swiper-button-next'),
          prevEl: $('.produtos-relacionados').find('.swiper-button-prev'),
        },
        breakpoints: {
          640: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 4
          },
        }
      })
    },
    setTabs: function () {
       $('.tabs .titulo').on('click', function(){
        var id = $(this).data('content');

        $('.tabs .titulo').removeClass('active');
        $(this).addClass('active');

        $('.contents .content').removeClass('active');
        $(id).addClass('active')
      })
    },
    setSizesPopup: function () {
      $('.open-guide').on('click', function(){
        $('.modal-guide').addClass('open');
        $('.overlay-modal-guide').addClass('open');
      })
      $('.modal-guide .close').on('click', function(){
        $('.modal-guide').removeClass('open');
        $('.overlay-modal-guide').removeClass('open');
      })
      $('.overlay-modal-guide').on('click', function(){
        $('.modal-guide').removeClass('open');
        $('.overlay-modal-guide').removeClass('open');
      })
    },
    setCollapse: () => {
      const handlers = document.querySelectorAll('[data-collapse-handler]')
      const icons = document.querySelectorAll('[data-collapse-icon]')
      const contents = document.querySelectorAll('[data-collapse-content]')

      let contentsHeights = []

      console.log(handlers)
      console.log(icons)
      console.log(contents)

      window.addEventListener('load', () => {
        contents.forEach(e => {
          contentsHeights.push(e.clientHeight)
          e.style.height = '0px'
        })
  
        handlers.forEach((e, i) => {
          e.addEventListener('click', () => {
            if(icons[i].innerHTML == '+') {
              icons[i].innerHTML = '-'
            } else {
              icons[i].innerHTML = '+'
            }
    
            if(contents[i].style.height == '0px') {
              contents[i].style.height = contentsHeights[i] + 'px'
            } else {
              contents[i].style.height = '0px'
            }
    
          })
        })
      })
    }, 
    openZoom: () => {
      const handler = document.querySelector('[data-zoom-handler]')
      const images = document.querySelector('[data-zoom-images]')

      handler.addEventListener('click', () => {
        images.classList.toggle('active')
      })

      images.addEventListener('click', () => {
        images.classList.toggle('active')
      })
    },
    init: function () {
      var _this = this;

      _this.showFirstImages();
      _this.setGallery();
      _this.submitNotify();
      _this.infosCompra();
      _this.setTabs();  
      _this.setRelated();    
      _this.setSizesPopup();  
      _this.setCollapse()  
      _this.openZoom()
      handleLoadVideos();
      Shipping.init()

      $(document).on('submit', store.config.selectorFormProduct, function (event) {
        event.preventDefault();

        var $form = $(this);
        var $parent = $form.closest(store.config.selectorWrapperProduct);

        var $btnComprar = $parent.find(store.config.selectorBtnBuy);

        var respValidated = store.validateFormProduct($form);
        var $boxResponse = $parent.find('.resp-validate');

        if (respValidated.validated) {
          store.addItem($form, $parent);
        } else {
          //console.info('não foi');
          store.setRespValidateProduct(respValidated, $form, $boxResponse);
        }
      });
    }
  },
  tag: {
    orderSizes: function () {
      var sort = {
          'pp': 0,
          'p': 1,
          'm': 2,
          'g': 3,
          'gg': 4,
          'ggg': 5,
          'u': 99,
          'other': -1 // qualquer valor diferente dos acima fica em primeiro na ordenação
      }
    
      var $boxFilters = $('[data-list-filters-tamanho]');
      
      $boxFilters.each(function(index, el) {
    
        var $filtersNum = [];
        var $filtersText = [];
    
        $(this).find('[data-filter-tamanho]').each(function(index, el) {
         if (!parseInt($(el).text())) {
            $filtersText.push($(el));
          } else {
            $filtersNum.push($(el));
          }
        });
    
        $filtersText.sort(function (a, b) {
    
          var filterA = sort[(a.text()).toLowerCase().trim()] || sort['other'];
          var filterB = sort[(b.text()).toLowerCase().trim()] || sort['other'];
    
          if (filterA < filterB) {
              return -1;
            } else if(filterA > filterB) {
              return 1;
            } else {
              //{% comment %}console.log( "deu treta texto" ){% endcomment %}
            }
          });
          // Ordena os filtros númericos em ordem crescente
          $filtersNum.sort(function (a, b) {
            var filterA = parseInt(a.text().toLowerCase());
            var filterB = parseInt(b.text().toLowerCase());
    
            if (filterA < filterB) {
              return -1;
            } else if(filterA > filterB) {
              return 1;
            } else {
              //console.log( "deu treta numero" )
            }
          });
    
        $(this).append($filtersText);
        $(this).append($filtersNum);
      });
    },
    catMobile: function () {
      $('.btn-cat').on('click', function(){
        $(this).toggleClass('active');
        $('.filter .menu').toggleClass('active');
      })
    },
    clearFilters: function () {
      $(document).ready(function() {
        if(document.location.search.length) {
          $('.clear--filters').show();
        } else {
          $('.clear--filters').hide();
        }
      }); 
    },
    openFilter: function () {
     $('.open-filter').on('click', function(){
        if ($('#filtros').innerHeight() > 0){
          $('#filtros').css('height', 0)
        } else {
          $('#filtros').css('height', $('#filtros').find('.container').innerHeight() + 50)
        }
      })
    },
    setFilterSelected:function(){
      var sPageURL = decodeURI(window.location.search.substring(1)),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        // console.log('nome do parametro', sParameterName[0])
        // console.log('valor do parametro', sParameterName[1])

        if($('#filtros [name="' + sParameterName[0] + '"][value="' + sParameterName[1] + '"]').length > 0){
          // console.log('achou')
          $('#filtros [name="' + sParameterName[0] + '"][value="' + sParameterName[1] + '"]').closest('li').addClass('selected')
          $('#filtros [name="' + sParameterName[0] + '"][value="' + sParameterName[1] + '"]').attr('checked', true)
        }
      }
    },
    setMobileFilters: () => {
      const handler = document.querySelector('[data-filters-handler]')
      const filters = document.querySelectorAll('#filtros')

      const filtersHeight = filters[1].clientHeight
      filters[1].style.height = '0px'

      handler.addEventListener('click', () => {
        if(filters[1].style.height == '0px') {
          filters[1].style.height = filtersHeight + 'px'
        } else {
          filters[1].style.height = '0px'
        }
      })

    },    
    init: function () {
      var _this = this;
      _this.orderSizes();
      _this.catMobile();
      _this.setMobileFilters()
      //_this.clearFilters();
      _this.openFilter();
      handleLoadVideos();
      template_store.tag.setFilterSelected()  
    }
  },
  login: {
    init: function () {
      $('[data-content-login]').each(function (index, el) {
        // Animação
        $(el).on('click', '[data-forget-password]', function (event) {
          event.preventDefault();
          $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
          $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('click', '[data-forget-password-back]', function (event) {
          event.preventDefault();
          $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
          $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('submit', '[data-form-login]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateLogin($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });

        $(el).on('submit', '[data-form-register]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRegister($form);

          if (respValidated.validated) {
            store.login.logar($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });

        $(el).on('submit', '[data-form-recover]', function (event) {
          event.preventDefault();

          var $form = $(this);
          var respValidated = store.login.validateRecover($form);

          if (respValidated.validated) {
            store.login.recoverPassord($form);
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }
        });
      });
    }
  },
  page: {
    init: function(){
      $('.tab h2').on('click', function(){

        if ($(this).hasClass('active')){
           $('.tab').removeClass('active');
           $('h2').removeClass('active');
        }else{
           $('.tab').removeClass('active');
           $('h2').removeClass('active');
           $(this).closest('.tab').addClass('active');
           $(this).addClass('active');
        }        
      }) 
    }
  },
  // setCartModal: function(){

  //   $(".cart svg").click(function(e){
  //     if(e.target != $('.btn-close')){ // only if the target itself has been clicked
  //       $(".modal-carrinho").addClass('active');
  //     }
  //   });

  //   $('.modal-carrinho').on('click', '.btn-close', function(e){
  //     $('.modal-carrinho').removeClass('active');
  //     $('.modal-overlay').removeClass('active');
  //   })

  //   $(document).on('click', '[data-action="delete-item-cart"]', function (event) {
  //     event.preventDefault();

  //     var $item = $(this).closest('[data-item-cart]');
  //     var itemId = $item.data('item-id');

  //     store.deleteItem(itemId, $item, function (_$item, _cart) {
  //       template_store.headerCart.loadCart(_cart);
  //     });
  //   });
  // },
  setBackToTop: function(){
     $('.back-to-top').on('click', function(){
      $('html, body').animate({
        scrollTop: 0
      }, '500');
    })
  }, 
  setSearchModal: function(){
    const container = document.querySelector('header .search')
    const buttonOpenFormModal = container.querySelector('[data-search]')
    const form = container.querySelector('form')
    const inputSearch = form.querySelector('[type=search]')
    const buttonCloseFormModal = form.querySelector('.close')

    function openSearchModal() {
      form.classList.add('active');
    }
    function closeSearchModal() {
      form.classList.remove('active')
    }
    function addEventCloseOnEsc() {
      window.addEventListener('keydown', function(event) {
        if (event.key == 'Escape') closeSearchModal()
      })
    }
    function removeEventCloseOnEsc() {
      window.removeEventListener('keydown', addEventCloseOnEsc)
    }
    buttonOpenFormModal.addEventListener('click', function() {
      openSearchModal()
      inputSearch.focus()
      addEventCloseOnEsc()
    })
    buttonCloseFormModal.addEventListener('click', function() {
      closeSearchModal()
      removeEventCloseOnEsc()
    })   
  },

  setFixedHeader: function(){
    $(window).scroll(function(event){
      if ($(window).scrollTop() > 250) {
        $("header").addClass('fixed'); 
        $(".back-to-top").addClass('visible');
      } else {
        $("header").removeClass('fixed'); 
        $(".back-to-top").removeClass('visible');
      }
    });    
  },   
  setHoneyPot: function(){
       $('[data-webform]').on('submit', function (event) {
      event.preventDefault();
      var $form = $(this);

      var honeyPot = $(this).find('[name="vnda"]');

      if (honeyPot.length > 0) {
        if (honeyPot.val() != '') {
          console.info('ROBOT DETECTED');
          return false;
        }
      }

      store.submitForm($(this));
    });   
  },   
  setBuyProductBlock: function() {
    $(document).on('click', '.product-block [data-size]', function(){
      var sku = $(this).data('sku'),
          product_block = $(this).closest('.product-block');

      $(product_block).find('[data-size]').removeClass('active');
      $(this).addClass('active');
      product_block.find('[name="sku"]').val(sku);
    })

    $(document).on('submit', '[data-add-cart="product-block"]', function (e) {
      e.preventDefault();
      console.log(this)
      console.log('this')
      console.log($(this).find('.product-block'))
      
      var $form = $(this)
      
      var $parent = ($(this).closest('.product-block')),
          respValidated = store.validateFormProduct($form),
          $boxResponse = $(this).find('.msg-response');

      if (respValidated.validated) {
        store.addItem($form, $parent);
      } else {
        store.setRespValidateProduct(respValidated, $form, $boxResponse);
      }
    });

    // select first size
    let productBlocks = document.querySelectorAll('.product-block');
    if (productBlocks.length > 0) productBlocks.forEach(block => {
      let sizes = block.querySelectorAll('.size');
      let sizeSelected = false;
      if (sizes.length > 0) sizes.forEach(size => {
        if (!size.classList.contains('inactive') && !sizeSelected) {
          size.click();
          sizeSelected = true;
        }
      });
    });
  },
  init: function () {
    var _this = this;
    store.getCartCount();
    store.getPriceProd();
    // _this.setCartModal();
    _this.setBuyProductBlock();
    _this.setHoneyPot();
    _this.setFixedHeader();
    _this.setSearchModal();
    _this.setBackToTop();
    _this.headerCart.initDrawer();


    template_store.setMenuMobile();      

    window.addEventListener('load', () => {
      AOS.init();
    })
  }  
}

$(function (){
  template_store.init();

  if (page == 'home') {
    template_store.home.init();
  }

  if (page == 'product') {
    template_store.product.init();
  }

  if (page == 'tag') {
    template_store.tag.init();
  }

  if (page == 'login') {
    template_store.login.init();
  }

  if (page == 'page') {
    template_store.page.init();
  }
});


console.log('%cVnda - Tecnologia em Ecommerce', 'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;');
